import React from "react"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import box from "../../../images/svg/menu.svg"
import Right from "../../../images/svg/left-arrow.svg"
import Left from "../../../images/svg/right-arrows.svg"


const digitalservice = () => {
  return (
    <>
      <section className="exclusive">
        <div className="container">
          <h1 className="page-title website-graphic">
            Connect Your Business To The World With Productive Digital Marketing
          </h1>

          <div className="row pt-4">
            <div className="col-12 col-lg-4">
              <ul className="service-graphic">
                <li>Search Engine Optimization</li>
                <li>Pay-Per-Click Advertising</li>
                <li>Social Media Management</li>
                <li>Content Marketing</li>
                <li>Email Marketing</li>
                <li>Copywriting </li>
                <li>Conversion Rate Optimization</li>
                <li> Voice Search Content Optimization</li>
              </ul>
            </div>
            <div className="col-12 col-lg-2 d-none d-lg-block"></div>
            <div className="col-12 col-lg-4 d-none d-lg-block">
              <ul className="service-graphic">
                <li>Website Content Creation</li>
                <li> Press Release Publishing</li>
                <li>Video Content</li>
                <li>EBook Writing</li>
                <li>Script Writing</li>
                <li>Technical Content</li>
                <li>White Papers</li>
              </ul>
            </div>
            <div className="col-12 col-lg-12 text-center page-next py-4">
              <div className="d-flex align-items-center justify-content-center">
              <Link to="/services/website-mobile-app-development">
                {" "}
                <img src={Right} alt="" className="navigation-left"></img>{" "}
                <span>
                Website &amp; Mobile Application Development
                </span>
             
              </Link>
              <img src={box} className="navigation-btn" alt="" ></img>
              <Link to="/services/advertising-promotional">
                {" "}
                <span>
                Advertishing &amp; Promotional MIX
                </span>
               
                <img src={Left} alt="" className="navigation-right"></img>     
              </Link>
              </div>
             
            </div>
       
          </div>
        </div>
      </section>
    </>
  )
}

export default digitalservice
