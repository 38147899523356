import React from "react"

const digitalcontent = () => {
  return (
    <section className="exclusive">
      <div className="container pt-4">
        <div className="row">
          <div className="col-12 col-lg-12 ">
            <p className="services-digital text-center">
              Get your brand in the limelight with beautiful visual content!
            </p>
            <p className="story-text pt-4">
              Mass Media Creatives consists of the most creative digital
              marketers that love to come up with new marketing ideas.
              Irrespective of whether you want to increase the conversions or
              the traffic to your website, we have just the right solution for
              you. We will create the most productive digital marketing campaign
              for you that will extend your global outreach.
            </p>
            <p className="story-text">
              {" "}
              Our value propositions include quality, brand awareness,
              streamlined processes, team-building, problem solving and
              storytelling to help you gain a competitive edge in today’s
              market. We craft digital marketing solutions that eliminate your
              toughest challenges.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default digitalcontent
