import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import DigitalMarketingPage from "../../components/services/digital-marketing/index"
const digitalmarketingPage = () => {
  return (
    <Layout>
      <Seo
        title="Services - Digital Marketing"
        desc=""
        pathname=""
        keywords=""
      />
      <DigitalMarketingPage />
    </Layout>
  )
}

export default digitalmarketingPage
