import React from "react"
import Slider from "./slider"
import Digital from "./digital"
import Gallery from "./gallery"
import Content from "./content"
import Services from "./services"
import Portfolio from "../../portfolio/portfolio"
import Service from "../../home/services"
import Clients from "../../home/clients"

const digitalpage = () => {
  return (
    <>
      <Slider />
      <Digital />
      <Gallery />
      <Content />
      <Services />
      <Portfolio />
      <Service />
      <Clients />
    </>
  )
}

export default digitalpage
